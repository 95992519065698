<template>
  <b-card
    class="salesrefunditem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="salesrefunditemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退款单ID"
            label-for="refund_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="refund_id"
              size="sm"
              v-model="salesrefunditem.refund_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退款单编号"
            label-for="refund_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="refund_no"
              size="sm"
              v-model="salesrefunditem.refund_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="salesrefunditem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="salesrefunditem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="预计退货数量"
            label-for="salesreturnqty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="salesreturnqty"
              size="sm"
              v-model="salesrefunditem.salesreturnqty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际退货数量(良品)  "
            label-for="salesreturnqty_good"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="salesreturnqty_good"
              size="sm"
              v-model="salesrefunditem.salesreturnqty_good"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际退货数量(不良品)  "
            label-for="salesreturnqty_bad"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="salesreturnqty_bad"
              size="sm"
              v-model="salesrefunditem.salesreturnqty_bad"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="含税单价(售价)"
            label-for="product_cost_tax"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_cost_tax"
              size="sm"
              v-model="salesrefunditem.product_cost_tax"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="成本价"
            label-for="cost_price"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="cost_price"
              size="sm"
              v-model="salesrefunditem.cost_price"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import salesrefunditemStore from './salesrefunditemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      salesrefunditem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('salesrefunditem')) store.registerModule('salesrefunditem', salesrefunditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesrefunditem')) store.unregisterModule('salesrefunditem')
    })

    const edit = function() {
      store.dispatch('salesrefunditem/edit', {id: this.id}).then(res => {
        this.salesrefunditem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('salesrefunditem/view', {id: this.id}).then(res => {
        this.salesrefunditem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('salesrefunditem/save', this.salesrefunditem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
